import React from 'react'
import "./features.css"
import pic from '../../images/corner.png'
import { Fade } from 'react-reveal'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { baseUrl } from '../../API'


const Features = () => {
  const [count, setCount] = useState([])

  useEffect(() => {
  axios.get(baseUrl+"api/chainlist/")
  .then((res) =>{
  // console.log("Getting from ::::", res.data.banner)
  setCount(res.data.middle)
  })
  },[])


  return (
    <Fade>
    <div className="push__possibility section__padding" id="possibility">
    {count.map((middle)=>{
  const {id, image} = middle;
  return <div className="push__possibility-image" key={id}>
      <img src={baseUrl+image} alt="possibility" />
    </div> })}
    {count.map((middle) => {
      const {id,title,description} = middle;
      return<div className="push__possibility-content" key={id}>
      <h1 className="gradient__text">{title}</h1>
      <p>{description}</p>
      
    </div>
    })}
  </div>
  </Fade>
  )
}

export default Features
