import React, { useState } from "react";

import axios from "axios";
import './article.css';




const Article = () =>{



  const [formValues, setFormValues] = useState({
    email: "",
    
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("http://adminecosystem.uptimiseit.com:8000/api/subscribe-form/", formValues)
      .then((response) => {
        alert("Form submitted successfully!");
        setFormValues({ email: "" });
        window.location.reload();
      })
      .catch((error) => {
        alert("Error submitting form. Please try again.");
      });
  };


    return(
      <form onSubmit={handleSubmit}>
      <div className="push__full">
        
        <div className="push__cta">
        
        <div className="push__cta-btn">
        <h1 style={{textAlign:"center"}}>Subscribe To Newsletter : </h1>
     
        <div className='sub_box'>
              <input type='email' placeholder='Email Address' name='email' value={formValues.email}
        onChange={handleInputChange}/> 
              <button type="submit" className="butt"><i class="fa-sharp fa-solid fa-greater-than"></i></button>
            </div> 

         
      </div>
      
      
    </div></div>
    </form>
      
    )
}

export default Article;