import React from 'react';

import "./header.css"
import { motion } from 'framer-motion';
// import Fade from 'react-reveal/Fade';
import img from '../../images/Push-Logo-Standard-Dark.png'
import { useState,useEffect } from 'react'; 
import {baseUrl} from "../../API"
import Loader from '../../Loader'
import axios from 'axios';
import { Link } from 'react-router-dom';
const Header = () => {

  const [count, setCount] = useState([])

useEffect(() => {
axios.get(baseUrl+'api/chainlist/')
.then((res) =>{
// console.log("Getting from ::::", res.data.banner)
setCount(res.data.banner)

}).catch((error) => {
  if( error.response ){
      console.log(error.response.data); // => the response payload 
  }
});
},[])


if(count.length == 0){
  return <Loader />;
}

  return (
    <div>
     
  
  {count.map(banner => (
   <div className="push__header" id="home" key={banner.id}>
      
    <div className="push__header-content-two">
   
      <p>{banner.title}</p>

      <div className="push__header-content__input">
     <Link to='/project/list'><motion.button initial={{y:600}} animate={{ y:0}} transition={{type:'spring', bounce:0.4}} type="button" >Latest Projects</motion.button></Link>
     <Link to='/project/list'><motion.button initial={{y:600}} animate={{ y:0}} transition={{ delay:0.5,type:'spring', bounce:0.4}} type="button">Search Projects</motion.button></Link>
      </div>
    </div>
    <div className='push__header-content-one'>
<img src={baseUrl+banner.image} />
</div>
  </div> ))}
  
    </div>
  
  )
}

export default Header
