import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../API";
import Loader from "../Loader";
import "./containers.css";
import imgs from '../images/project-icon-24.png'
import imgs1 from '../images/project-icon-2.jpg'
import imgs2 from '../images/market.avif'

const Banner = () => {


  const [totalProjects, setTotalProjects] = useState(0);
  const [activeProjects, setActiveProjects] = useState(0);

  useEffect(() => {
    axios.get(baseUrl+"api/get_total_project_count/")
      .then(response => {
        const data = response.data;
        setTotalProjects(data.total_projects);
        setActiveProjects(data.active_projects);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  

  if (totalProjects == 0) {
    return <Loader />;
  }



  return (
    <div className="pro_banner">
      <div className="box">
        <div className="pros"><img src={imgs}/></div>
        <p className="paras">Total no. of Projects</p>
        <p className="value">{totalProjects}</p>
      </div>
      <div className="box">
      <div className="pros"><img src={imgs1}/></div>
        <p className="paras">Active Projects</p>
        <p className="value"> {activeProjects}</p>
      </div>
      {/* <div className="box">
      <div className="pros"><img src={imgs2}/></div>
        <p className="paras">Market Cap</p>
        <p className="value">$75369456</p>
      </div> */}
    </div>
  );
};

export default Banner;
