import React from 'react'
import './possibility.css'
import { useEffect,useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import pic from '../../images/ref-finance.jpg'
import pic1 from '../../images/aurora-dev.jpg'

import pic3 from '../../images/octopus-network.jpg'
import pic4 from '../../images/paras.jpg'
import pic5 from '../../images/sweat-economy.jpg'
import pic6 from '../../images/learn-near.jpg'
import pic7 from '../../images/ref-finance.jpg'
import pic8 from '../../images/aurora-dev.jpg'
import pic9 from '../../images/octopus-network.jpg'
import pic10 from '../../images/paras.jpg'
import pic11 from '../../images/meta-pool.jpg'
import pic12 from '../../images/sweat-economy.jpg'
import Slider from 'react-slick'
import Carousel from 'react-elastic-carousel'
import "react-multi-carousel/lib/styles.css"
import Fade from 'react-reveal/Fade'
import { baseUrl } from '../../API'

const Possibility = () => {
  const [count, setCount] = useState([])

  useEffect(() => {
  axios.get(baseUrl+"api/chainlist/")
  .then((res) =>{
  // console.log("Getting from ::::", res.data.banner)
  setCount(res.data.trending)
  })
  },[])

  const breakPoints = [
    {width:1 , itemsToShow:1},
    {width:550 , itemsToShow:2},
    {width:768 , itemsToShow:3},
    {width:1200, itemsToShow:4}
  ];
  return (

    <div className='project glass'>
					
          <div className='center'><h1 > Trending Projects</h1> </div>

          
				
					<Fade >
					<div className='pp'>
          <Carousel className='dd'  breakPoints={breakPoints}>
          
          {count.map((trending)=>{
  const {id, image, tile} = trending;
  return  <Link to={`/project-detail/${tile}`} key={id}><div className='dar' key={id}><img src={baseUrl+image} alt="" /><h4>{tile}</h4></div></Link>
  })}
  {/* <div className='dar'><img src={pic1} alt=""/><h4>Neft</h4></div>
 
  <div className='dar'><img src={pic3} alt=""/><h4>Blockchain</h4></div>
  <div className="dar"><img src={pic4} alt=""/><h4>Data Provider</h4></div>
					<div className="dar"><img src={pic5} alt=""/><h4>Node Operater</h4></div>
					<div className="dar"><img src={pic6} alt=""/><h4>Dex</h4></div>
					<div className="dar"><img src={pic7} alt=""/><h4>Play to Earn</h4></div>
          <div className="dar"><img src={pic8} alt="" /><h4>Defi</h4></div>
					<div className="dar"><img src={pic9} alt=""/><h4>Neft</h4></div>
				
					<div className="dar"><img src={pic11} alt=""/><h4>Blockchain</h4></div>
					<div className="dar"><img src={pic12} alt=""/><h4>Data Provider</h4></div> */}
          
</Carousel>
</div>
</Fade>
          </div>
        
  )
}

export default Possibility;
