import React, { useEffect, useState } from "react";
import "./project-Detail.css";
import pic from "../images/gradienta-QWutu2BRpOs-unsplash.jpg";
import { Chip,Avatar, Modal } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import axios from "axios";
import 'swiper/swiper-bundle.min.css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Popup from 'reactjs-popup';
import Loader from '../Loader'
import truncate from 'lodash/truncate';
import {ShareSocial} from 'react-share-social' 
import Carousel from "react-elastic-carousel";

import bann from "../images/bann.jpg";
import pic1 from '../../src/images/okx.png';
import pic2 from '../../src/images/kucoin.png';
import pic3 from '../../src/images/binance.png';
import pic4 from  '../../src/images/bbs.png';
import { Link } from "react-router-dom";
import { baseUrl } from "../API";
import Chat from "./Chat";
import Swipe from "./Swiper";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const ProjectDBanner = () => {
  const [count, setCount] = useState([]);
  const [buy, setbuy] = useState(false)

  const { name } = useParams();

  const [data, setData] = useState(null);

useEffect(() => {
  axios.get(baseUrl + `api/crypto-data/${name}/`)
    .then(response => {
      setData(response.data);
      console.log('kkii',response.data); // log the data to console
    })
    .catch(error => {
      console.error(error);
    });
}, [name]);



const [buys, setBuys] = useState([]);

  useEffect(() => {
    axios.get(baseUrl + "api/buy_links/")
      .then(response => setBuys(response.data))
      .catch(error => console.error(error));
  }, []);


const regex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm;


const [showMore, setShowMore] = useState(false);


  function toggleShowMore() {
    setShowMore(!showMore);
}


const renderDescription = () => {
  let description = project.description;
  if (!showMore && project.description.length > 1325) {
    description = project.description.substring(0, 1325);
  }
  return description.replace(/<[^>]+>/g, '');
};


 
  const [project, setProject] = useState(false);

  useEffect(() => {
    axios.get(baseUrl + "api/chainlist/").then((res) => {
      console.log("Getting from ::::", res.data.banner);
      setCount(res.data.project);
    });
  }, []);


  useEffect(() => {
    axios.get(baseUrl + "api/chainlist/").then((res) => {
      // console.log("Getting from ::::", res.data.banner)
      setCount(res.data.trending);
    });
  }, []);


  const [vote, setVote] = useState(0);

  const handleClick = () => {
    setVote(vote === 0 ? 1 : 0);
  };

  useEffect(() => {
    axios
      .get(baseUrl + `api/pro-detail/${name}/`)
      .then((response) => {
        console.log("ghhhhhg",response.data)
        setProject(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [name]);

  if (!project) {
    return <Loader />;
  }
  function removeHtmlTags(str) {
    if (!str) return str;
    return str.replace(/<(?!\/?a(?=>|\s.*>))\/?.*?>/g, '');
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  const brePoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];



  
  const shareUrl = `https://pushecosystem.com/project-detail/${name}`;
  const shareTitle = `Check out ${project.title} on our website!`;
  const shareDescription = project.description;
  const shareImage = project.imageUrl;



  
  return (
    <div className="full-del">
   <Swiper className="swe"
  modules={[Navigation, Pagination, Scrollbar, A11y]}
  spaceBetween={50}
  slidesPerView={1}
  navigation
  pagination={{ clickable: true }}
  scrollbar={{ draggable: true }}
  onSlideChange={() => console.log('slide change')}
  onSwiper={(swiper) => console.log(swiper)}
>
  {project.banners.length === 0 ? (  // Check if banners array is empty
    <SwiperSlide className='swipe'>
      <img className="del-img-1" src={bann} alt="Default Image" />
    </SwiperSlide>
  ) : (
    project.banners.map(banner => (
      <SwiperSlide className='swipe' key={banner.id}>
        <img className="del-img-1" src={baseUrl+banner.images} alt={banner.id} />
      </SwiperSlide>
    ))
  )}

{regex.test(project.video) ? (
  <SwiperSlide className="swipe">
    <iframe
      src={project.video}
      title="YouTube video player"
      frameborder="0"
      allow=""
      allowfullscreen
    ></iframe>
  </SwiperSlide>
) : null}
</Swiper>
    
<div>
<div className='diso'>
  

         
         {/* <Chip className="bbt" avatar={<Avatar>C</Avatar>} label="Buy Coin Name" /> */}
<div>
<Chip className="buy-chip" avatar={<Avatar>B</Avatar>} onClick={() => setbuy(!buy)} color="primary" label={
    <>
        Buy Coin Now&nbsp;
        {buy ? <i className="fa fa-chevron-up" style={{ color: 'white' }}></i> : <i className="fa fa-chevron-down" style={{ color: 'white' }}></i>}
    </>
}/>




<div className={buy ? "buy-coin active" : "buy-coin"}>

{buys.map((item, index) => (
  
  <div className="buy-coun-con" key={item.title}>
    <img src={baseUrl+item.image} alt="" />
  <div className="buy-coin-card" >
    <a href={item.link} target="_blank" >
      <h4>{item.title}</h4>
      <p style={{ color: 'gray', fontSize: '11px', marginTop: '3px' , marginBottom: '5px', textDecoration: 'none'}}>{item.description}</p>
    </a>
    {/* {index < buys.length - 1 && <hr />} check if it is not the last item */}
  </div>
  </div>
))}
 
 {/* <div className="buy-coin-card">
   <h5>Lorem ipsum dolor</h5>
   <p>wwww.facebook.com</p>
 </div>
 <div className="buy-coin-card">
   <h5>Lorem ipsum dolor</h5>
   <p>wwww.facebook.com</p>
 </div >
 <div className="buy-coin-card">
   <h5>Lorem ipsum dolor</h5>
   <p>wwww.facebook.com</p>
 </div>
 <div className="buy-coin-card">
   <h5>Lorem ipsum dolor</h5>
   <p>wwww.facebook.com</p>
 </div> */}
</div>
</div>
       
        </div>
</div>
      <div className="frst-white">
        <div className=" frst-white-sub">
          <div className="dist-btn">
            <div className="loc">
              <div className="tit-img">
                <img src={baseUrl+project.image} />
               
                  <h1>{project.tile}</h1>
                
              </div>
              <div>
                {" "}
                
                <Chip className="st-b" avatar={<Avatar>D</Avatar>} label={project.category} color="primary" variant="outlined" />
                {/* <Chip className="st-b" avatar={<Avatar>N</Avatar>} label="NFT " color="primary" variant="outlined" />
                <Chip className="st-b" avatar={<Avatar>G</Avatar>} label="Gaming" color="primary" variant="outlined" /> */}
              </div>
            </div>
          </div>

          <div>
          <div className="del-para">
          <div style={{ fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif" }} dangerouslySetInnerHTML={{ __html: showMore ? project.description : truncate(project.description, { length: 500, separator: /,?\.* +/ }) }}>
</div>

{project.description.length > 500 && (
  showMore ? (
    <button className="readmo" style={{ fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif" }} onClick={toggleShowMore}>Read less</button>
  ) : (
    <button className="readmo" style={{ fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif" }} onClick={toggleShowMore}>Read More</button>
  )
)}
</div>

          </div>
        </div>
       
        <div className="stat-card">
          <div>
            <div className="stat-card-cont">
              <div className="push_status_left">
                <h5>Status</h5>
              </div>{" "}
              <div className="push_status_right">
                <p className="">

                <Chip 
  avatar={<Avatar>
            <span 
              className="live-icon" 
              style={{
                backgroundColor: project.live_status === "Live" ? "green" :
                                   project.live_status === "Up-Coming" ? "yellow" :
                                   project.live_status === "Expire" ? "red" :
                                   "green" // default to green if live_status is null or undefined
              }}
            />
          </Avatar>} 
  label={project.live_status || 'live'} 
/>

                
                </p>
              </div>
            </div>
            <div className="stat-card-cont">
              <div className="push_status_left">
                <h5>Chains</h5>
              </div>{" "}
              <div className="push_status_right">
                <p className="push_right_chain_title"></p>
                <p className="push_right_chain">{project.chain_address}</p>
              </div>
            </div>
            <div className="stat-card-cont">
              <div className="push_status_left">
                <h5>Year</h5>
              </div>{" "}
              <div className="push_status_right">
                <p>{project.year}</p>
              </div>
            </div>
            {/* <div className="stat-card-cont">
              <div className="h5">
                <h5>Market Cap</h5>
              </div>{" "}
              <div className="sst">
                <p>23654</p>
              </div>
            </div> */}
            <div className="stat-card-cont">
              <div className="push_status_left">
                <h5>Top Exchange</h5>
              </div>{" "}
              <div className="push_status_right">
              <Link  to={project.okx}   target="_blank"><img className="push_but_1" src={pic1} alt="" /></Link>
              <Link  to={project.kucoin} target="_blank"><img className="push_but_2" src={pic2} alt="" /></Link>
              <Link  to={project.binance} target="_blank"><img className="push_but_3" src={pic3} alt="" /></Link>
              </div>
            </div>
           
            <div className="stat-card-cont">
              <div className="push_status_left">
                <h5>Project Announcement</h5>
              </div>{" "}
              <div className="push_status_right">
                {/* <Link to={project.project_announcement}><button>visit here</button><i class="fa-solid fa-arrow-up-right-from-square"></i></Link> */}
                {project.project_announcement ? (
  <Chip
    component="a"
    href={project.project_announcement}
    target="_blank"
    label="Visit here"
    avatar={
      <Avatar>
        <i className="fa-solid fa-arrow-up-right-from-square"></i>
      </Avatar>
    }
  />
) : (
  <Chip label="Not available" disabled />
)}
              </div>
            </div>
            <div className="stat-card-cont">
              <div className="push_status_left">
                <h5>Partnership Type</h5>
              </div>{" "}
              <div className="push_status_right">
              <p>{project.partnership_type ? project.partnership_type : "Not available"}</p>
              </div>
            </div>
            <div className="stat-card-cont">
              <div className="push_status_left">
                <h5>Partnership Anouncement</h5>
              </div>{" "}
              <div className="push_status_right">
   
               {/* <Link to={project.partnership_announcement_link}><button>visit here</button><i class="fa-solid fa-arrow-up-right-from-square"></i></Link> */}
               {project.partnership_announcement_link ? (
               <Chip
  component="a"
  href={project.partnership_announcement_link}
  target="_blank"
  label={project.partnership_announcement_link ? "Visit here" : "Not available"}
  avatar={
    <Avatar>
      <i className="fa-solid fa-arrow-up-right-from-square"></i>
    </Avatar>
  }
  />
  ) : (
    <Chip label="Not available" disabled />
  )}

              </div>
            </div>
            <div className="stat-card-conts">
              <div className="push_status_left">
                <h5>Social links</h5>
              </div>{" "}
              <div >
                <p className="">
                  {" "}
                  <ul class="social-icons">

                  {project.facebook_link ? (
                    <li>
                      <a href={project.facebook_link}   target="_blank" class="social-icon">
                        {" "}
                        <i class="fa-brands fa-facebook-f "></i>
                      </a>
                    </li>
 ) : null}



{project.linkedin_link ? (
                    <li>
                      <a href={project.linkedin_link}  target="_blank" class="social-icon">
                        {" "}
                        <i class="fa-brands fa-linkedin-in "></i>
                      </a>
                    </li>
) : null}


{project.twitter_link ? (
                    <li>
                      <a href={project.twitter_link}  target="_blank"  class="social-icon">
                        {" "}
                        <i class="fa-brands fa-twitter "></i>
                      </a>
                    </li>
        ) : null}           
                   

                   {project.github_url ? (   
                    <li>
                      <a href={project.github_url}  target="_blank" class="social-icon">
                        {" "}
                        <i class="fa-brands fa-github "></i>
                      </a>
                    </li>
  ) : null}  




                  </ul>
                </p>
              </div>
            </div>
            <div>
            <Popup
    trigger={<Chip avatar={<Avatar><FontAwesomeIcon icon={faShareNodes} style={{color:"blue"}} /></Avatar>} label="Share" />}
    modal
    nested
  >
    { close => (
      <div className="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <div className="header"> Share</div>
        <div className="content">
          {' '}
          <ShareSocial
      url={shareUrl}
      title={shareTitle}
      description={shareDescription}
      image={shareImage}
      socialTypes={['facebook', 'twitter', 'linkedin', 'pinterest', 'whatsapp', 'telegram']}
      openWindow={true}
    />
        
        </div>
       
      </div>
    )}
  </Popup>
        
           
  
            {/* <Chip avatar={<Avatar><i class="fa-solid fa-share-nodes" style={{color:"blue"}}></i></Avatar>} label="Share" /> */}
            
            <Chip avatar={<Avatar><i class="fa-solid fa-heart" onClick={handleClick} style={{color:"red"}}></i></Avatar>} label={`Vote (${vote})`}  style={{marginLeft:"55px"}} />
            </div>
          </div>
        </div>
      </div>

      <Chat className="bg-white" />
      {project.investors && project.investors.length > 0 && (
  <div className="container-invest">
    <h1>Invested By</h1>
    <div className="members">
      {project.investors.map(investor => (
        <div className="member" key={investor.id}>
          <img src={baseUrl + investor.image} alt={investor.name} />
          <h2 className="member-name">{investor.name}</h2>
          {/* <span className="member-role">Founder & CEO</span> */}
        </div>
      ))}
    </div>
  </div>
)}
      <div className="space">
  {project.related_project && project.related_project.length > 0 ? (
    <div className="related-projects">
      <h1 className="klo">Related projects</h1>
      <Carousel className="dd" breakPoints={breakPoints}>
        {project.related_project.map(related => (
          <Link to={`/project-detail/${related.tile}`} key={related.id}>
            <div className="dar" key={related.id}>
              <img src={baseUrl+related.image} alt="" />
              <h4>{related.title}</h4>
            </div>
          </Link>
        ))}
      </Carousel>
    </div>
  ) : null}
</div>
      {/* <div className="investor">
        <div className="investors-head">
        <h1>Investors</h1>
        </div>
        <div className="investors-pics">
          <div>
            <img src={pic1} alt="" />
            <img src={pic2} alt="" />
            <img src={pic1} alt="" />
            <img src={pic2} alt="" />
            <img src={pic1} alt="" />
            <img src={pic2} alt="" /><img src={pic1} alt="" />
            <img src={pic2} alt="" />
            <img src={pic2} alt="" />
            </div>
        </div>
      </div>

      <div className="star">
        <div className="star-box">
        <div className="star-box-contant"><h3>Status</h3><p>Live</p></div>
        <div className="star-box-contant"><h3>Chains</h3><p>EIT</p><p>BSC</p></div>
        <div className="star-box-contant"><h3>Chains</h3></div>
        <div className="star-box-contant"><h3>Chains</h3></div>
        </div>
      </div>

      <div className="investor-pro-share">
        <h1 >Share Project name</h1>
        <div>
        <ul className="wrapp">
  <li className="icon facebook">
    <span className="tooltip">Facebook</span>
    <span><i className="fab fa-facebook-f"></i></span>
  </li>
  <li className="icon twitter">
    <span className="tooltip">Twitter</span>
    <span><i className="fab fa-twitter"></i></span>
  </li>
  <li className="icon instagram">
    <span className="tooltip">Instagram</span>
    <span><i className="fab fa-instagram"></i></span>
  </li>
  <li className="icon github">
    <span className="tooltip">Github</span>
    <span><i className="fab fa-github"></i></span>
  </li>
  <li className="icon youtube">
    <span className="tooltip">Youtube</span>
    <span><i className="fab fa-youtube"></i></span>
  </li>
</ul>
        </div>
      </div>

      <div className="related-projects">
    <h1>Related Projects</h1>
        <div className="related-cont">
<div className="related-projects-box">
  <img src={pic3} />
  <p>lorem</p>
</div>
<div className="related-projects-box">
  <img src={pic3} />
  <p>lorem</p>
</div>
<div className="related-projects-box">
  <img src={pic3} />
  <p>lorem</p>
</div>
<div className="related-projects-box">
  <img src={pic3} />
  <p>lorem</p>
</div>
        </div>

      </div> */}
    </div>
  );
};

export default ProjectDBanner;
