import React from 'react'
import { Navbar ,Article} from './components'
import ContactSec from './contact/ContactSec'
import { Footer } from './containers'
import '../src/contact/contact.css'

const Contact = () => {
  return (
    <div className='olx' >
      <Navbar />
      <ContactSec />
      <Article />
      <Footer />
    </div>
  )
}

export default Contact
