import { Footer, Blog, Possibility, Header, Push,Features} from './containers'
import { Cta,Brand,Navbar,Article } from './components'
import './App.css'


import React from 'react'

const Home = () => {
  
  return (
    <div > 
       <div className='gradient__bg'>
        <Navbar />
        <Header />
        <Cta />
      </div>
      
      <Brand/>
      <Possibility/>
      <Push />
      <Features />
      
      <Blog />
      <div className='prop'>
      <Article />
      <Footer />
     
      </div>
    </div>
  )
}

export default Home
