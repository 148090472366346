import React from 'react'
import { Article, Navbar } from './components'
import { Footer } from './containers'
import '../src/editProject/editProject.css'
import EditProject from './editProject/EditProject'


const Edit = () => {
  return (
    <div className='olx' >
      <Navbar />
      <EditProject />
      <Article />
     
      <Footer />
    </div>
  )
}

export default Edit
