import React, { useState ,useEffect} from "react";
import "./navbar.css";
import { useHistory } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import Fade from "react-reveal/Fade";
import { Link, NavLink } from "react-router-dom";
import pic from "../../images/Bell.png";
import axios from 'axios';

import { motion } from "framer-motion";
import { baseUrl } from "../../API";

const Navbar = () => {


  const [products, setProducts] = useState([]);
  const [input,setInput] = useState(false)


  useEffect(() => {
      axios.get(baseUrl+'api/pros/').then(response => {
        console.log("ggg",response.data);
        setProducts(response.data);
      });
   
    
  }, []);



    const [searchTerm, setSearchTerm] = useState('');
 const [projects, setProjects] = useState([]);
  
    const handleSearchSubmit = (event) => {
      event.preventDefault();
      fetch(baseUrl+`api/projects-search/${searchTerm}`)
        .then(response => response.json())
        .then(data => {
          setProjects(data.projects);
        });
    };
  
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
  








  const [count, setCount] = useState([])

  useEffect(() => {
    axios.get(baseUrl+"api/general-setting/").then(response => {
      setCount(response.data.general);
    });
  }, []);
  const [toggleMenu, setToggleMenu] = useState(false);

  const [value,setValue] = useState("")
  
const onChange = (event) =>{
  setValue(event.target.value)
}

const onSearch = (searchTerm) =>{
  console.log('search', searchTerm)
}

  return (
    <>
   
    <div className="push__navbar">
    {count.map(projects => ( 
      <div className="push__links">
      
        <div className="push__navbar-links_logo">
          <Link to="/">
            <img src={baseUrl+projects.image} />
          </Link>
        </div>
        <div className="push__navbar-links_containers">
          <Fade top>
            <motion.p
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.1, type: "spring", bounce: 0.2 }}
            >
              <NavLink activeClassName="active"  className="rob" to="/">
                Home
              </NavLink>
            </motion.p>

            <motion.p
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.3, type: "spring", bounce: 0.2 }}
            >
              <NavLink activeClassName="active" className="rob" to="/project/list">
                Projects
              </NavLink>
            </motion.p>
            <motion.p
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, type: "spring", bounce: 0.2 }}
            >
              <NavLink activeClassName="active" className="rob" to="/learn">
                Learning
              </NavLink>
            </motion.p>
            <motion.p
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, type: "spring", bounce: 0.2 }}
            >
              <NavLink activeClassName="active" className="rob" to="/contact">
                Contact Us
              </NavLink>
            </motion.p>
           
            <motion.p
              className="green"
              to="/"
              initial={{ y: -400 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.5, type: "spring", bounce: 0.2 }}
            >
              <NavLink className="twi" activeClassName="active" target="_blank" to={projects.twitter_link}>
                <i class="fa-brands gl fa-twitter"></i> Twiter
              </NavLink>
            </motion.p>


          </Fade>
        </div>
      </div>
        ))}
      <div className="push__navbar-join">
      <form className="srch-bx" onSubmit={handleSearchSubmit}>
        <div>
        <input className={input ? "nav-inp active" : "nav-inp " } type="text" value={value} onChange={onChange}  />
        <div className="dropd">
        {products.filter(item => {
          const searchTerm = value.toLocaleLowerCase();
          const fullName = item.tile.toLocaleLowerCase();

          return searchTerm && fullName.startsWith(searchTerm)
        }).map(projects => (
         <ul className={input ? "nav-inp active" : "nav-inp " }  key={projects.id}>
            {<Link to={`/project-detail/${projects.tile}`}><li onClick={() => onSearch(value)}>{projects.tile}</li></Link>}
          </ul>))}
        </div>
        </div>

        {input ? <button type="submit" className="srch-btn" onClick={() => setInput(!input)} ><i class="fa-solid fa-xmark" aria-hidden="true"></i></button> :<button type="submit" className="srch-btn" onClick={() => setInput(!input)} ><i class="fa fa-search" aria-hidden="true"></i></button>}
      </form>
      {/* <ul>
        {products.map(project => (
          <li key={project.id}>
            <Link to={`/project-deail/${project.id}`}>{project.tile}</Link>
          </li>
        ))}
      </ul> */}
        {/* <i class="fa fa-search" aria-hidden="true"></i> */}
        <Link to="/contact">
          <button className="buttonss" type="button"> Add Projects</button>{" "}
        </Link>
      </div>

      <div className="push__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#000"
            size={47}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#000"
            size={47}
            onClick={() => setToggleMenu(true)}
          />
        )}

        {toggleMenu && (
          <div className="push__navbar-menu_container scale-up-center">
            <div className="push__navbar-menu_container-links">
              <p>
                <Link to="/">Home</Link>
              </p>

              <p>
                <Link to="/project/list">Projects</Link>
              </p>

              <p>
                <Link to="/learn">Learning</Link>
              </p>
              <p>
                <Link to="/contact">Contact Us</Link>
              </p>
              <p>
                <Link className="twi" to="/">
                  <i class="fa-brands gl fa-twitter"></i> Twiter
                </Link>
              </p>
            </div>
            <div className="push__navbar-menu_container-links-sign">
            <i class="fa fa-search" aria-hidden="true"></i>
              <button className="buttonss"  type="button">Add Projects</button>
            </div>
          </div>
        )}
      </div>
    </div>
  
    </>
  );
};

export default Navbar;
