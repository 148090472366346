import React, { useState, useEffect } from "react";
import "./cta.css";

// import Fade from 'react-reveal/Fade';

import { motion } from 'framer-motion';
import { baseUrl } from "../../API";
import axios from "axios";

const Cta = () => {


  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    axios
      .get(baseUrl + 'api/chainlist/')
      .then((res) => {
        // Extracting "api_data" from the response data
        const apiData = res.data.api_data;
        setApiData(apiData);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data); // Log the response payload for error handling
        }
      });
  }, []);


  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    // <Fade>
    <section className="sec">
    {apiData && (
      <div className="re">
        <motion.div className="sec_sub">
          <h4><span> Price</span> ${formatNumberWithCommas(apiData.price.toFixed(4))}</h4>
        </motion.div>
       
        <motion.div className="sec_sub">
          <h4> <span>Volume 24h</span>  ${formatNumberWithCommas(Math.trunc(apiData.volume_24h))}</h4>
        </motion.div>
        <motion.div className="sec_sub">
          <h4><span>Market Cap</span>  ${formatNumberWithCommas(Math.trunc(apiData.market_cap))}</h4>
        </motion.div>
      </div>
    )}
  </section>
    // </Fade>
  );
};

export default Cta;