import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../API";
import "./containers.css";
import Loader from "../Loader";
import {FcAlphabeticalSortingAz} from 'react-icons/fc'
import {FcFilledFilter} from 'react-icons/fc'
import imgs from "../images/visualization.png";


import { Link, NavLink, useParams } from "react-router-dom";
import { Chip,Avatar,sx } from "@material-ui/core";
const Containers = () => {
    const [Gridview,setGridView] = useState(true)
    const [Listview,setListView] = useState(true)
    const [demo, setDemo] = useState(false);
    const [more, setMore] = useState(false);
    const [shows, setshows] = useState(false);

//    Category
  const [categoryId, setCategoryId] = useState(null);

  const [chainss, setChainId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chains, setChains] = useState([]);
  const [types, setTypes] = useState([]);
  const [allProjects, setallProjects] = useState([]);


  const { id:projectCategoryId } = useParams();


  const [sortedData, setSortedData] = useState([]);

  const handleSort = (event) => {
    const value = event.target.value;
    let sorted;
  
    switch(value) {
      case 'name-asc':
        ascendingEvent()
        break;
      case 'name-desc':
        descendingEvent()
        break;
      case 'price-high':
        sorted = products.sort((a, b) => b.price - a.price);
        break;
      case 'price-low':
        sorted = products.sort((a, b) => a.price - b.price);
        break;
      case 'marketcap-high':
        sorted = products.sort((a, b) => b.marketcap - a.marketcap);
        break;
      case 'marketcap-low':
        sorted = products.sort((a, b) => a.marketcap - b.marketcap);
        break;
      case 'date-old':
        oldDateEvent()
        break;
      case 'date-new':
        newDateEvent()
        break;
      default:
        sorted = products;
    }
  
    setSortedData(sorted);
  };






// sorting
const ascendingEvent = () => {
  let data = [...products]
  if(data.length > 0) {
   let result = data.sort((a,b) => a.tile.localeCompare(b.tile))
   setProducts(result)
  }
}

const reset = () => {
 
  setProducts([...products])
}
  



const descendingEvent = () => {
  let data = [...products]
  if(data.length > 0) {
   let result = data.sort((a,b) => b.tile.localeCompare(a.tile))
   setProducts(result)
  }
}



const oldDateEvent = () => {
  let data = [...products]
  if (data.length > 0) {
    let result = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    setProducts(result)
  }
}

const newDateEvent = () => {
  let data = [...products]
  if (data.length > 0) {
    let result = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    setProducts(result)
  }
}



// Use effect
  useEffect(() => {
    axios.get(baseUrl+"api/categories/").then(response => {
     
      setCategories(response.data.category);
    });
  }, []);


  // Use effect
  useEffect(() => {
    axios.get(baseUrl+"api/chains/").then(response => {
     
      setChains(response.data.chain);
    });
  }, []);


  // Use effect
  useEffect(() => {
    axios.get(baseUrl+"api/types/").then(response => {
     
      setTypes(response.data.type);
    });
  }, []);




  // useEffect(() => {
  //   axios.get("api/projects/").then(response => {
  //     setallProjects(response.data.projects);
  //   });
  // }, []);

  const resets = async () =>{
    return await axios
    .get(baseUrl+'api/pros/')
    .then((response) => setProducts(response.data))
    
  }
 
  // useEffect(() => {
  //   if (categoryId === null) {
  //     axios.get(baseUrl+'api/pros/').then(response => {
  //       const shortDescriptions = response.data.map(product => ({
  //         ...product,
  //         short_description: product.short_description.slice(0, 50) + "..."
  //       }));
  //       setProducts(shortDescriptions);
  //     });
  //   }
  // }, [categoryId]);

  useEffect(() => {
    if (projectCategoryId !== null && projectCategoryId === "list") {
      axios.get(baseUrl+'api/pros/').then(response => {
              const shortDescriptions = response.data.map(product => ({
                ...product,
                short_description: product.short_description.slice(0, 50) + "..."
              }));
              setProducts(shortDescriptions);
            });
    }else if(projectCategoryId !== null && projectCategoryId !== "list"){
      axios.get(baseUrl+`api/pros/?category_id=${projectCategoryId}`).then(response => {
        setProducts(response.data);

      });
    }
  }, [projectCategoryId]);
  

  useEffect(() => {
    if (categoryId !== null) {
      axios.get(baseUrl+`api/pros/?category_id=${categoryId}`).then(response => {
        setProducts(response.data);

      });
    }
  }, [categoryId]);


  useEffect(() => {
    if (typeId !== null) {
      axios.get(baseUrl+`api/pros/?type_id=${typeId}`).then(response => {
        setProducts(response.data);

      });
    }
  }, [typeId]);



  useEffect(() => {
    if (chainss !== null) {
      axios.get(baseUrl+`api/pros/?chains=${chainss}`).then(response => {
        setProducts(response.data);

      });
    }
  }, [chainss]);


  function removeHtmlTags(str) {
    if (!str) return str;
    return str.replace(/<(?!\/?a(?=>|\s.*>))\/?.*?>/g, '');
  }

  function handleCategoryClick(id) {
    setCategoryId(id);
  }


  function handleChainClick(id) {
    setChainId(id);
  }


  function handleTypeClick(id) {
    setTypeId(id);
  }

const [filt, setFilt] = useState(false)

const handleRest = () =>{
  setProducts()
}

if (projectCategoryId.length == 0) {
  return <Loader />;
}



  return (
    <div className="full_container">
        <div className={filt ? "fils aps" : "fils"}>
        <div className="filters">
    <div className='filter_head'>
     <h1 className=''> Filters</h1>
     <Link to="/project/list">
     <button onClick={()=> resets()} >Clear Filters</button></Link>
    </div>
    <div>
    <div className='catie'>
        <h4 >Categories</h4>
      <p> { demo ?<i class="fa-solid fa-minus" onClick={() => setDemo(false)}></i>:<i class="fa-solid fa-plus" onClick={() => setDemo(true)}></i>}</p>
    </div>
    <div className='cati_sub'>
        <div className='buts'>
        {categories.map(category => (
          <Chip sx={{
            height: 'auto'
          }}  avatar={<Avatar alt="Natacha" src={baseUrl+category.image} />} key={category.id} onClick={() => handleCategoryClick(category.id)} label={category.name} variant="outlined"/>
   
    ))}
   
   
    </div>
    </div>
    </div>
    <div>
    <div className='catie'>
        <h4 >Chain</h4>
        <p> { more ?<i class="fa-solid fa-minus" onClick={() => setMore(false)}></i>:<i class="fa-solid fa-plus" onClick={() => setMore(true)}></i>}</p>
    </div>
    <div className='cati_sub'>
        <div className='buts'>
        {chains.map(chain => (
          <Chip sx={{
            height: 'auto'
          }}  avatar={<Avatar alt="Natacha" src={baseUrl+chain.image} />} key={chain.id} onClick={() => handleChainClick(chain.id)} label={chain.name} variant="outlined"/>
   
    ))}
   
    </div>
    </div>
    </div>
    <div>
    <div className='catie'>
        <h4>Product</h4>
        <p> { shows ?<i class="fa-solid fa-minus" onClick={() => setshows(false)}></i>:<i class="fa-solid fa-plus" onClick={() => setshows(true)}></i>}</p>
    </div>
    <div className='cati_sub'>
        <div className='buts'>
       
        {types.map(type => (
          <Chip sx={{
            height: 'auto'
          }}  avatar={<Avatar alt="Natacha" src={baseUrl+type.image} />} key={type.id} onClick={() => handleTypeClick(type.id)} label={type.name} variant="outlined"/>
   
    ))}
   
 
    </div>
    </div>
    </div>
    </div>
          </div>
      <div className="containi">
        <div className="container_head">
          <div className="side-b">
         { filt ? <i class="fa-solid fa-xmark" onClick={() => setFilt(false)}></i>: <FcFilledFilter className="fil-icon"  onClick={()=> setFilt(true)}></FcFilledFilter> } <span>Filters</span>
          </div>
         <div className=" grid">
         <div className="grid-icon" onClick={()=>setListView(true)}>
              <img  src={imgs} />
            </div>
            
           <div className="list-icon" onClick={()=>setListView(false)}>
              <i class="fa-solid fa-bars listu" ></i>
            </div>
          </div>
          <div className="sort">
            <div className="hs">
              {" "}
              <h5>Sort by :</h5>
              
            </div>

            <select onChange={handleSort}>
  <option value="name-asc">Name (a-z)</option>
  <option value="name-desc">Name (z-a)</option>
  <option value="date-old">Date (old)</option>
  <option value="date-new">Date (new)</option>
</select>
            
          </div>
        </div>


     



       {Listview ? 
       <div className="con">
       {products.map(projects => (
          <Link to={`/project-detail/${projects.tile}`} key={projects.id}>
            <div className="carda">
              <img className="card_img" src={projects.image} />
              <p><Chip  className="live-chip" size="small"
  avatar={<Avatar>
            <span 
              className="live-icon" 
              style={{
                backgroundColor: projects.live_status === "Live" ? "green" :
                                   projects.live_status === "Up-Coming" ? "yellow" :
                                   projects.live_status === "Expire" ? "red" :
                                   "green" 
              }}
            />
          </Avatar>} 
  label={projects.live_status || 'Live'} 
  style={{fontSize: "10px", padding: "4px 6px"}}
/>
</p> 
              <div className="card_body">
                <div className="frst_prt-one">
                  {/* <h1>0002</h1>  */}
                  <img src={projects.image} />{" "}
                  <h1>
               
                  </h1>
                </div>
                <div className="frst_prt-two">
                  <h5>{projects.tile}</h5>
                </div>
                <div className="frst_prt-three">
                  <p
                   dangerouslySetInnerHTML={{__html: projects.short_description}}>
                  </p>
                </div>
                <div className="frst_prt-three">
                  <ul>
                  {projects.facebook_link ? (
                    <li>
                      <a href={projects.facebook_link}  rel="noopener" onClick={(e) => {
      e.preventDefault(); 
      window.open(projects.facebook_link, '_blank');
    }}>
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                      ) : null}

{projects.github_url ? (
                    <li>
                    
                    <a href={projects.github_url}  rel="noopener" onClick={(e) => {
      e.preventDefault(); 
      window.open(projects.github_url, '_blank');
    }}>
                        <i class="fa-brands fa-github"></i>
                      </a>
                    </li>
 ) : null}

{projects.twitter_link ? (
  <li>
    <a href={projects.twitter_link}  className="social-icon" rel="noopener" onClick={(e) => {
      e.preventDefault(); 
      window.open(projects.twitter_link, '_blank');
    }}>
      {" "}
      <i className="fa-brands fa-twitter"></i>
    </a>
  </li>
) : null}      
                   
                  </ul>
                </div>
                <div className="frst_prt-four">
                  {/* <p className="price">$100</p> */}
                  {/* <p className="marcap">Market Cap</p> */}
                </div>
              </div>
            </div>
          </Link>))}
        
        </div>

       :

        <div className="list-co">

{products.map(projects => (
   <Link to={`/project-detail/${projects.tile}`} key={projects.id}>
          <div className="lis">
            <div className="list-img">
              <img src={projects.image} />
            </div>
            <div className="list-contant">
              <div className="list-head">
                <div><h2>{projects.tile}</h2></div>
                <div className="list-right"> <p><Chip size="small"
  avatar={<Avatar>
            <span 
              className="live-icon" 
              style={{
                backgroundColor: projects.live_status === "Live" ? "green" :
                                   projects.live_status === "Up-Coming" ? "yellow" :
                                   projects.live_status === "Expire" ? "red" :
                                   "green" 
              }}
            />
          </Avatar>} 
  label={projects.live_status || 'Live'} 
/>
</p> </div>
                
               
              </div>
<div className="list-des">
                    <p
                   dangerouslySetInnerHTML={{__html: projects.short_description}}>
                  </p>
              </div>
              <div className="list-bottom">
                {/* <div className="list-num"> <h4>Price- $100 </h4></div> */}
                <div className="list-social">
                <ul>
                  {projects.facebook_link ? (
                    <li>
                      <a href={projects.facebook_link}  rel="noopener" onClick={(e) => {
      e.preventDefault(); 
      window.open(projects.facebook_link, '_blank');
    }}>
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                      ) : null}

{projects.github_url ? (
                    <li>
                    
                    <Link to={projects.github_url}  rel="noopener" onClick={(e) => {
      e.preventDefault(); 
      window.open(projects.github_url, '_blank');
    }}>
                        <i class="fa-brands fa-github"></i>
                      </Link>
                    </li>
 ) : null}

{projects.twitter_link ? (
                    <li>
                      <a href={projects.twitter_link} className="social-icon" rel="noopener" onClick={(e) => {
      e.preventDefault(); 
      window.open(projects.twitter_link, '_blank');
    }}>
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                     ) : null}
                  </ul>
                </div>
                {/* <div className="list-market"><h4>Market Cap- 0325</h4></div> */}
              </div>
            </div>
          </div>
           </Link>
))}

        
        </div>}
      </div>
    </div>
  );
};

export default Containers;
