import React from 'react'
import { Link } from 'react-router-dom'
import pic from "../../images/Bell.png";
import "./footer.css"
import axios from 'axios';
import { useState, useEffect } from 'react';
import { baseUrl } from '../../API';

const Footer = () => {
  const [count, setCount] = useState([])

  useEffect(() => {
    axios.get(baseUrl+"api/general-setting/").then(response => {
      setCount(response.data.general);
    });
  }, []);
  return (
    <div>
      {count.map(projects => ( 
      <div className='footer'>
  
      <div className='footer_box'>
        <div className='footer_box_social'>
        <div className='push__footer_logo marg'>
        <div className="push__footer-links_logo">
          <Link to="/">
            <img src={baseUrl+projects.image} />
          </Link>
        </div>
            <p>{projects.title}</p>

            
            </div>
            <div className='footer_box_discover marg'>
                <h3 className='head'>Discover</h3>
                <ul>
                  <li><Link to='/'>Home</Link></li>
                  <li><Link  to='/project'>Project</Link></li>
                  <li><Link to='/learn'>Learning</Link></li>
                  <li><Link to='/contact'>Contact Us</Link></li>
                </ul>
            </div>
            <div className='footer_box_help marg'>
              <h3 className='head'>Help Center</h3>
              <ul>
                  <li><Link><i className="fa-solid fa-phone"></i> {projects.contact1}</Link></li>
                  <li><Link><i className="fa-solid fa-location-dot"></i> {projects.address1}</Link></li>
                  <li><Link><i className="fa-solid fa-at"></i> {projects.email1}</Link></li>
                  
                </ul>
            </div>

            <div className='subscribe marg'>
              <h3 className='head'>Social Media</h3>
           

              <div className='footer_social'>
              <Link to={projects.facebook_link} target="_blank"><i className="fa-brands fa-facebook-f"></i></Link>

              <Link to={projects.instagram_link} target="_blank"><i className="fa-brands fa-instagram"></i></Link>
            
            <Link to={projects.twitter_link} target="_blank"><i className="fa-brands fa-twitter"></i></Link>

            <Link to={projects.linkdein_link} target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
            
           
            </div>
            
            </div>
        </div>
      </div>
      
    </div>))}
   

  
    </div>
  )
}

export default Footer
