import React from 'react'

import { Article, Navbar } from './components'
import { Footer } from './containers'
import Banner from './project/Banner'
import Containers from './project/Containers'





const Project = () => {
  return (
    <div >
        <Navbar />
        <Banner />
      <Containers />
      <Article />
      <Footer />
    </div>
  )
}

export default Project
